// 
// layout: footer
//

.header{
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    transition: all 0.3s;
    
    .header-row{
        position: relative;
        padding: 35px 110px;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .header-brand{
        position: relative;
        img{
            display: block;
            position: relative;
            z-index: 2;
        }
    }
    ul.header-menu{
        display: flex;
        align-self: center;
        margin: 0;
        padding: 0;
        li{
            margin-right: 7px;
            margin-left: 7px;
        }
        li a{
            color: $white;
            font-size: 14px;
            padding: 10px 25px;
            display: block;
            position: relative;
            text-transform: uppercase;
            &:hover{
                background-color: $primary;
            }
        }
        li a.active{
            background-color: $primary;
        }
    }
    .header-right {
        display: flex;


        ul.header-lang {
            display: flex;
            align-self: center;
            margin: 0;
            padding: 0;
            li {
                margin-right: 0;
                margin-left: 0;
            }

            li a {
                border-right: 1px solid $gray-300;
                padding: 15px 10px;
                color: $white;
                display: block;
                &:hover{
                    background-color: $primary;
                }
            }
        }
        ul.header-social {
            display: flex;
            align-self: center;
            margin: 0;
            padding: 0;
            margin-left: 10px;
            li {
            }

            li a {
                padding: 15px 10px;
                color: $white;
                display: block;
                &:hover{
                    color: $primary;
                }
            }
        }
    }


    .header-toggler{
        display: none;
    }
}

.redirect {
    position: fixed;
    right: -112px;
    top: 50%;
    z-index:999;
    transition: all .4s cubic-bezier(.365,.84,.44,1);
    &:hover{
        right:0;
    }
    transform: translateY(-50%);
    .redirect-title {
        background-color: $primary;
        padding: 20px;
        font-weight: bold;
        color: $white;
        transform: translate(-50%,-50%) rotate(90deg);
        position: absolute;
        top: 50%;
        left: -30px;
        width:235px;
        text-align:center;
    }
    .redirect-companies {
        background-color: $white;
        margin-bottom: 0;
        padding: 0 10px;
        li {
            padding: 35px 10px;
            &:first-of-type {
                border-bottom: 1px solid $gray-300;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .header {
        ul.header-menu li a {
            padding-left: 15px;
            padding-right: 15px;
        }
        .header-right {

            right: 0;
            ul.header-social {
                padding-right: 15px;
            }
        }
    }
}
@include media-breakpoint-down(xl) {
    .header {
        .header-row {
            padding: 25px;
        }
        ul.header-menu li a {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
@include media-breakpoint-down(lg) {

    .header{
        .header-brand{
            img{
                max-width: 100px;
            }
        }
        ul.header-menu li {
            a {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .header-row {
            padding: 25px;
        }
        .header-right {
            right: 15px;
            .header-lang {
                padding-top: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .header{
        ul.header-menu li {
            margin-right: 5px;
            margin-left: 5px;
            a {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .header-right {
            ul.header-social {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .header {
        position: fixed;
        width: 100%;
        .header-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            background-color: $black;
            &:after {
                content: none;
            }
        }
        .header-brand {
            margin-bottom: 0;
            img {
                max-height: 50px;
                height: auto;
            }
        }
        .header-toggler{
            padding-top: 15px;
            margin-left: 10px;
        }
        ul.header-menu, .header-phone, ul.header-lang, .header-phone .phone-gradient {
            display: none;
        }
        .header-toggler{
            display: block;
            padding-top: 15px;
            margin-left: 10px;
        }

    }
    .redirect {
        display: none;
    }
}

