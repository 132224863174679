
.section{
    position: relative;
    overflow: hidden;
}

.section-relative{
    position: relative;
}

.section-title {
    padding-top: 30px;
    h1, .h1 {
        font-size: 60px;
        font-weight: 400;
        margin-bottom: 50px;
    }
}

.section-builders {
    padding: 50px 0 ;
    h3 {
        font-size: 40px;
        margin-bottom: 30px;
    }
    p {
        font-family: Montserrat, sans-serif;
        font-weight: 300;
    }
}

.section-chefs {
    padding-top: 50px;
    padding-bottom: 60px;
    .chef-item {
        padding: 0 10px;
        .chef-content {
            padding: 20px 40px;
            h6 {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: bold;

            }
            h4{
                text-transform: uppercase;
                margin-bottom: 30px;
                font-size: 26px;
            }

            p {
                font-family: Montserrat, sans-serif;
                font-weight: 300;
                font-size: 14px;
            }
        }
    }

}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(md) {
    .section-builders {
        h3, .h3 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        img {
            margin-bottom: 30px;
        }
    }
    .section-title {
        h1, .h1  {
            font-size: 40px;
            margin-bottom: 1rem;
        }
    }
    .section-chefs {
        .chef-item {
            padding: 0;

            .chef-content {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

}


@include media-breakpoint-down(sm) {

}



