// 
// page: about
// 

.page-home {
  .section-about {
    padding-top: 130px;
    h2, .h2 {
      font-size: 60px;
    }
  }
}

.section-about {
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 160px 0;
  h1, .h1 {
    font-size: 60px;
    margin-bottom: 40px;
  }
  h2, .h2 {
    font-size: 32px;
    margin-bottom: 40px;
  }
  p {
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
  }
  .btn {
    margin-top: 40px;
  }
  .about-image-wrapper {
    margin-top: -50px;
    margin-left: 100px;
    position: relative;
    .about-image-2, .about-image-3 {
      position: absolute;
    }
    .about-image-1 {
      max-width: 43%;
      z-index: 1;
      position: relative;
    }
    .about-image-2 {
      left: 45%;
      top: 50%;
      transform: translateY(-50%);
    }
    .about-image-3 {
      bottom: -25%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;

    }
  }
}

@include media-breakpoint-down(xl) {
  .page-home {
    .section-about {
      h2, .h2 {
        font-size: 45px !important;
      }
    }
  }
  h2, .h2 {
    font-size: 26px !important;
  }
}

@include media-breakpoint-down(lg) {
  .page-home {
    .section-about {
      h2, .h2 {
        font-size: 40px !important;
      }
    }
  }
  .section-about {
    h2, .h2 {
      font-size: 23px !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .page-home {
    .section-about {
      padding-top: 50px !important;
      padding-bottom: 130px !important;
      h2, .h2 {
        font-size: 30px !important;
      }
    }
  }
  .section-about {
    background: transparent !important;
    padding-top: 50px;
    padding-bottom: 100px !important;
    .about-image-wrapper {
      margin-top: 30px;
      margin-left: 0;
    }
    h2, .h2 {
      font-size: 30px !important;
    }
    h1, .h1 {
      font-size: 40px !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .page-home {
    .section-about {
      padding-top: 50px !important;
      padding-bottom: 100px !important;
      h2, .h2 {
        font-size: 30px !important;
        margin-bottom: 30px;
      }
      .btn {
        margin-top: 30px;
      }
    }
  }
  .section-about {
    padding-top: 50px;
    padding-bottom: 50px !important;
    .about-image-wrapper {
      margin-top: 30px;
    }
    h2, .h2 {
      font-size: 30px !important;
    }
  }

}


