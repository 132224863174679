// 
// page: about
// 

.section-voucher {
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 160px 0;
  h1, .h1 {
    font-size: 60px;
    margin-bottom: 40px;
  }
  h2, .h2 {
    font-size: 32px;
    margin-bottom: 40px;
  }
  p {
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
  }
  .btn {
    margin-top: 40px;
  }
  .voucher-image {
    margin-top: -80px;
    position: relative;
  }
}

.section-voucher-form {
  padding: 100px 0;
  h5, .h5 {
    font-size: 26px;

  }
  h2, .h2 {
    font-size: 40px;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .btn {
    width: 100%;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 20px;
  }
  .form-control {
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    &.no-left-border {
      border-left: none;
    }
  }
  p {
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    strong {
      font-weight: 700;
    }
  }
}

@include media-breakpoint-down(xl) {
  .section-voucher {
    h2, .h2 {
      font-size: 26px !important;
    }
  }

}

@include media-breakpoint-down(lg) {

  .section-voucher {
    h2, .h2 {
      font-size: 23px !important;
    }
  }
  .section-voucher-form {
    padding-top: 0;

    h2, .h2 {
      font-size: 40px !important;
    }
  }

}

@include media-breakpoint-down(md) {
  .section-voucher {
    .voucher-image {
      margin-top: 30px;
    }
    padding-top: 50px;
    padding-bottom: 100px !important;
    h2, .h2 {
      font-size: 30px !important;
    }
    h1, .h1 {
      font-size: 40px !important;
    }
  }
  .section-voucher-form {
    padding-bottom: 10px !important;
    h2, .h2 {
      font-size: 30px !important;
    }
  }

}

@include media-breakpoint-down(sm) {
  .section-voucher {
    padding-top: 50px;
    padding-bottom: 50px !important;
    h2, .h2 {
      font-size: 20px !important;
    }
  }
  .section-voucher-form {
    .form-control {
      &.no-left-border {
        border-left: 1px solid $gray-300 !important;
      }
    }
    h2, .h2 {
      font-size: 30px !important;
    }
  }

}


