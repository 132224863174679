// 
// page: contact
//

.section-contact {
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 50px 0;
  h1, .h1 {
    font-size: 60px;
    margin-bottom: 80px;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    margin-left: -20px;
    border-bottom: 1px solid $gray-300;
  }

  .contact-form {
    h4 {
      border-bottom: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
    .form-control, .btn {
      background-color: $white;
    }

    .form-control[type=email] {
      border-left: 0;
    }
    .custom-control {
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }

  table {
    width: 100%;
    tr td:last-of-type {
      text-align: right;
      font-size: 30px;
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {
  .section-contact {
  }

}

@include media-breakpoint-down(md) {
  .section-contact {
    h4 {
      padding-right: 20px;
      margin-right: -20px;
    }
    h1, .h1 {
      font-size: 40px !important;
    }
    .contact-form {
      h4 {
        padding-left: 0;
        margin-top: 40px;
      }
      .form-control[type=email] {
        border-left: 1px solid $gray-300;
      }
    }
  }

}

@include media-breakpoint-down(xs) {
  .section-contact {
    h4 {
      padding-right: 0px;
      margin-right: 0px;
      padding-left: 0px;
      margin-left: 0px;
    }
    table {
      tr td:last-of-type {
        font-size: 20px;
      }
    }
  }

}


