// 
// layout: offcanvas
//
.offcanvas {
    position: fixed;
    left: 0;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: $primary;
    transform: translate3d(0%, -100%, 0);
    z-index: 99;
    transition: all .4s;
    display: flex;
    flex-direction: column;
    padding: 120px 20px 20px 0px;
    overflow-y: auto;
    
    ul.offcanvas-menu{
        z-index: 44;
        padding: 0;
        margin: 0;
        > li a{

            font-size: 48px;
            font-weight: 300;
            color: $white;
            &:hover{
                color: $primary;
            }
        }
    }
    .offcanvas-inside {
        opacity: 0;
        transform: translateX(50px);
        transition: all 0.3s 0.4s;
        position: relative;
        z-index: 44;
        h5, .h5 {
            font-size: 22px;
            margin-top: 20px;
            color: $white;
            font-weight: $font-weight-bold;
        }
    }
}

.offcanvas.active{
    transform: translate3d(0, 0, 0);
    .offcanvas-inside {
        opacity: 1;
        transform: translateX(0px);
    }
}


@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(lg) {
    .offcanvas{
        padding-top: 90px;
    }
}

@include media-breakpoint-down(md) {
    .offcanvas{
        padding: 20px;
        padding-top: 90px;
    }
}


@include media-breakpoint-down(sm) {
    .offcanvas{
        ul.offcanvas-menu > li a{
            font-size: 18px;
        }
    }
}



