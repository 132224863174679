// 
// page: menu
// 

.section-menu {
  padding: 50px 0;
  h2, .h2 {
    font-size: 60px;
  }
  .menu-tab-content {
    padding-top: 35px;
    padding-bottom: 48px;
    padding-left: 70px;
    padding-right: 65px;

    .tab-content {
      padding: 35px 60px;
      position: relative;
      z-index: 10;
    }

    li.item {
      position: relative;
      margin-bottom: 20px;

      & > a {
        color: $text-color;
        border-bottom: 1px solid $gray-300;
        font-size: 23px;
        line-height: 21px;
        font-weight: bold;
        display: block;
        padding-left: 55px;
        padding-bottom: 15px;
        padding-top: 15px;

        &[data-toggle="collapse"] {
          color: $primary;
          &.collapsed {
            color: $text-color;

            &:hover {
              color: $primary;

              &:before {
                background-color: $primary;
                color: $white;
              }
            }

            &:before {
              background-color: $white;
              color: $text-color;
              content: "\e914";
            }
          }

          &:before {
            font-family: 'icomoon' !important;
            font-size: 16px;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            height: 52px;
            width: 36px;
            padding-top: 15px;
            border: 1px solid $gray-300;
            background-color: $primary;
            color: $white;
            transition: all .35s;
            content: "\e910";
          }

          /*&:hover {
            color: $text-color;

            &:before {
              background-color: $white;
              color: $text-color;
              content: "\e914";
            }
          }*/
        }
      }
    }
  }
  .menu-nav-tabs {
    margin-top: 40px;
    ul.nav-tabs {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
      li.nav-item {
        margin-right: 0;
        margin-bottom: 0;
        a.nav-link {
          border: none;
          border-radius: 0;
          color: $text-color;
          text-transform: uppercase;
          padding: 15px 45px;
          font-size: 20px;
          &:hover, &.active {
            color: $white;
            background-color: $primary;
          }
        }
      }
    }
  }
  .menu-download {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    span {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 300;

    }
    a.download-link {
      margin-left: 15px;
      text-transform: uppercase;
      color: $text-color;
      display: flex;
      font-weight: bold;
      font-size: 14px;
      i {
        align-self: center;
        margin-left: 5px;
        font-size: 18px;
      }
      &:hover {
        color: $primary;
      }
    }
  }
  ul.menu-list {
    padding-left: 55px;
    li {
      .menu-list-item {
        border-bottom: 1px solid $gray-300;
        display: flex;
        //align-items: center;
        padding: 25px 10px;
        margin-left: -10px;
        margin-right: -10px;

        .list-item-description {
          flex: 1;

          h6, .h6 {
            font-size: 18px;
            font-weight: 600;
            color: $text-color;
            margin-bottom: 5px;
          }

          p {
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            font-weight: 300;
            color: #060606;
            margin-bottom: 0;
          }
        }

        .list-item-price {
          width: 20%;
          text-align: right;

          .price {
            font-family: Montserrat, sans-serif;
            font-weight: 400;
            font-size: 30px;
            line-height: 30px;

            span {
              margin-left: 4px;
              font-weight: 300;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
.menus {
  .menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &.menu-left {
      background: url(../img/menu-left.jpg);
      background-position: left top;
      background-repeat: repeat-y;
      top: 35px;
      left: 44px;
    }

    &.menu-right {
      background: url(../img/menu-right.jpg);
      background-position: right top;
      background-repeat: repeat-y;
      right: 37px;
      top: 35px;
    }

    &.menu-top {
      background: url(../img/menu-top.jpg);
      background-position: left top;
      background-repeat: repeat-x;
      left: 34px;
      right: 34px;
    }

    &.menu-bottom {
      background: url(../img/menu-bottom.jpg);
      background-position: right bottom;
      background-repeat: repeat-x;
      left: 45px;
      right: 45px;
    }

    &.menu-left-bottom {
      background: url(../img/menu-left-bottom.png);
      background-position: left bottom;
      background-repeat: no-repeat;
      z-index: 5;
    }

    &.menu-right-bottom {
      background: url(../img/menu-right-bottom.png);
      background-position: right bottom;
      background-repeat: no-repeat;
      z-index: 5;
    }

    &.menu-left-top {
      background: url(../img/menu-left-top.png);
      background-position: left top;
      background-repeat: no-repeat;
      z-index: 5;
      left: 8px;
    }

    &.menu-right-top {
      background: url(../img/menu-right-top.png);
      background-position: right top;
      background-repeat: no-repeat;
      z-index: 5;
      right: 8px;
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {


}

@include media-breakpoint-down(md) {
  .section-menu {
    .menus {
      display: none;
    }
    .menu-tab-content {
      padding: 0;
      .tab-content {
        padding: 0;
        .tab-pane {
          margin-top: 1rem;
        }
      }
    }
    ul.menu-list {
      padding-left: 0;
    }
    .menu-download {
      margin-right: 0;
    }
    .menu-nav-tabs ul.nav-tabs li.nav-item a.nav-link {
      padding: 15px 25px;
      font-size: 16px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .section-menu {
    h2 {
      font-size: 36px;
    }
  }
}


