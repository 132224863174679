//
// component: hero
//

.hero-background{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100vh;
    &:after {
        content: '';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: rgba($black, .6);
    }
    .hero-image {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-size: cover;
        background-position: center 20%;
        background-repeat: no-repeat;
        animation: kenburns 30s;
    }
}

.hero-small {
    min-height: 750px;
}

.hero-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    text-align: center;
    min-width: 310px;
    max-width: 100%;
    .hero-border {
        padding: 100px 50px;
        position: relative;
        z-index: 5;
        .borders {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        .border-1:before, .border-1:after, .border-2:before, .border-2:after {
            content: '';
            position: absolute;
            background-color: $primary;
            transition: all 1s;
            transition-delay: .5s;
            height:0;
            width:0;
        }
        .border-1 {
            &:before {
                left:0;
                top:0;
                width: 2px;
            }
            &:after {
                right:0;
                bottom:0;
                width: 2px;
            }
        }
        .border-2 {
            &:before {
                left:0;
                top:0;
                height: 2px;
            }
            &:after {
                right:0;
                bottom:0;
                height: 2px;
            }

        }
        &.aos-animate {
            .border-1 {
                &:before {
                    height: 100%;
                }
                &:after {
                    height: 100%;
                }
            }
            .border-2 {
                &:before {
                    width: 100%;
                }
                &:after {
                    width: 100%;
                }
            }
        }
    }
    h3, .h3 {
        color: $primary;
        font-size: 32px;
        position: relative;
        z-index: 5;
    }
    h1, .h1 {
        font-size: 90px;
        color: $white;
        margin-bottom:0;
        position: relative;
        z-index: 5;
    }
}

@include media-breakpoint-down(xl) {
    .hero-background{
        min-height: 700px;
    }
    .hero-background.hero-small {
        min-height: 600px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 65px;
        }
    }
}
@include media-breakpoint-down(lg) {
    .hero-background{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 50%;
    }
    .hero-background{
        min-height: 600px;
    }
    .hero-background.hero-small {
        min-height: 500px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 45px;
        }
        h3, .h3 {
            font-size: 26px;
        }
    }
}

@include media-breakpoint-down(md) {
    .hero-background{
        min-height: 500px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 35px;
        }
        h3, .h3 {
            font-size: 22px;
        }
        .hero-border {
            padding: 50px 75px;

        }
    }
    .hero-background.hero-small {
        min-height: 400px;
    }
}

@include media-breakpoint-down(sm) {
    .hero-background.hero-small {
        //min-height: 5vh;
    }
    .hero-background {
        min-height: 400px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 34px;
        }
        h3, .h3 {
            font-size: 20px;
        }
        .hero-border {
            padding: 50px 25px;

        }
    }
    .hero-background.hero-small {
        min-height: 250px;
    }
}