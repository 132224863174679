// 
// page: home
//
.page-home {

}

.main-scroll {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  .link-top {
    position: relative;
    padding-bottom: 90px;
    text-transform: uppercase;
    line-height: 1;
    display: block;
    font-weight: 600;
    transition: all 0.3s;
    animation: anim-bounce-sm 0.85s ease-in-out infinite alternate;
  }

  .link-top:before {
    content: "\e900";
    font-family: 'icomoon' !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    bottom: 15px;
    font-size: 45px;
    transition: all 0.3s;
  }
}
@keyframes anim-bounce-sm {
  from {
    top: 0px; }
  to {
    top: 7px; }
}

.section-banner {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 160px 0;
  &:after {
    content: '';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba($black, .5);
  }
  .text-center {
    position: relative;
    z-index: 5;
  }
  h2, .h2 {
    color: $white;
    font-size: 60px;
  }
  h3, .h3 {
    color: $primary;
    font-size: 32px;
  }
  .btn {
    margin-top: 40px;
  }
}

.section-images {
  .row.no-gutters {
    margin-left: -5px;
    margin-right: -5px;
  }
  .image-holder {
    &:after {
      content: '';
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-color: rgba($black, .6);
      transition: all .25s;
    }
    &:hover:after {
      background-color: transparent;
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(sm) {

  .section-banner {
    h2, .h2 {
      font-size: 32px;
    }
    h3, .h3 {
      font-size: 24px;
    }
    .btn {
      margin-top: 20px;
    }
  }

}


